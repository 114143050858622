<template>
    <v-container>
        <div
            class="d-flex justify-start">
            <v-btn
            @click="()=>{
                $router.go(-1);
            }"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                </div>
            </v-col>
        </v-row>
        <v-card
            style="border-radius:4px 4px 0px 0px"
            v-if="this.subscriptionHistory!=null"
            color="secondary">
            <div
                class="text-h5 pa-5 white--text">
                Subscription history
            </div>
        </v-card>
        <v-list-item
            class="px-0"
            v-for="(item,index) in this.subscriptionHistory"
            :key="index">
            <v-container
                class="px-0 py-0">
                <v-card
                    style="border-radius:0px 0px 0px 0px">
                    <div
                        class="pa-4">
                        <div
                            class="d-flex justify-start text-h6 mb-4">
                            {{ item.year }}
                        </div>
                        <div
                            class="d-flex justify-start">
                            Report date
                            <span class="font-weight-bold mx-2">
                                {{ convertTimeZone(item.created_at) }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start ">
                            Year of subscription : 
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.subscription_year!=null?item.subscription_year:'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start ">
                            Status :
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.status}}
                            </span>
                        </div>
                       
                        <div
                            class="d-flex justify-start">
                            Price (RM) :
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.price!=null?item.price:'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start ">
                            Payment date
                            <span class="font-weight-bold mx-2">
                                {{ (item.payment_date!=null)?convertTimeZone(item.payment_date):'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            Subscription start date
                            <span class="font-weight-bold mx-2">
                                {{ (item.subscription_start_date!=null)?convertTimeZone(item.subscription_start_date):'-' }}
                            </span>
                        </div>

                        <div
                            class="d-flex justify-start">
                            Subscription end date
                            <span class="font-weight-bold mx-2">
                                {{ (item.subscription_end_date!=null)?convertTimeZone(item.subscription_end_date):'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            Commision release date
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.commision!=null?convertTimeZone(item.commision):'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start">
                            Salesperson PIC :
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.pic_salesperson!=null?item.pic_salesperson:'-' }}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start"
                            v-if="item.cancellation_reason!=null">
                            Cancellation reason : 
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.cancellation_reason }}
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-container>
        </v-list-item>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        renewalStatusOption:[
            'Pending',
            'Unreachable',
            'Confirm',
            'Confirm - Pending HRDC',
            'Rejected',
        ],
        searchCompanies:null,
        renewalStatusFiltered:null,
        companiesRenewal : null,
        filteredCompanies:null,
        companiesRenewalHeader :[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'Name',
                value:'company_name',
            },
            {
                text:'Status',
                value:'renewal_status',
            },
            {
                text:'Reported By',
                value:'user_name',
            },
            {
                text:'Reported Date',
                value:'created_at',
            },
            
        ],
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        subscriptionHistory:null,
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if (resp.class === "getSubscriptionHistoryByCompany") {
                this.subscriptionHistory = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.$api.fetch(this.fetchCompanySubscriptionHistory());
        },
        fetchCompanySubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription_history/company/" + this.$router.history.current.params.id;
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LLL");
        }
    }
}
</script>